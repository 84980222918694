import APIS from "../../http/apis";
import { Toast } from "vant";


const state = () => ({
    userInfoData: {}, //用户个人信息
    footerList: [], //底部list
    token: null,
    memberId: null,  // id
    chatData: null, // 直播间数据
    userActiveIndex: 0,  // 直播间数据
    listGift: [],    // 礼物
    isGiftsEffects: 0,  // 设置礼物id
    moneyRefresh: 0,   //  余额更新
    watchFun: false,  // 是否调用过一次初始化视频
    isEffects: true,  // 开关特性
    isLivePage: true,  // 全屏开关
    giftTop3: [],   // 排行榜
    carEffectList: [], // vip座驾特效

});

const mutations = {
    SET_USER_DATA(state, value) {
        state.userInfoData = value;
    },
    M_UPDATA_FOOTER(state, val) {
        state.footerList = val
    },
    M_TOKEN(state, val) {
        state.token = val
    },
    SET_MEMBER_ID(state, value) {
        state.memberId = value;
    },
    M_CHAT_DATA(store, payload) {
        store.chatData = payload
    },
    M_USER_ACTIVE_INDEX(store, payload) {
        store.userActiveIndex = payload
    },
    SET_LIST_GIFT(state, value) {
        state.listGift = value;
    },
    SET_GIFTS_EFFECTS(state, value) {
        state.isGiftsEffects = value;
    },
    SET_MONEY_REFRESH(state, value) {
        state.moneyRefresh = value;
    },
    SET_WATCH_FUN(state, bool) {
        state.watchFun = bool;
    },
    SET_IS_EFFECTS(state, value) {
        state.isEffects = value;
    },
    SET_IS_LIVEPAGE(state, value) {
        state.isLivePage = value;
    },
    SET_GIFT_TOP3(state, value) {
        state.giftTop3 = value;
    },
    SET_CAR_EFFECTLIST(state, value) {
        state.carEffectList = value;
    },
};

const actions = {
    async nuxtServerInit({ commit }, ctx) {
        let [FriendlyLinkList] = await Promise.all([
            ctx.$axios.post('', { apiName: "getFriendlyLinkList" })
        ])
        commit('M_UPDATA_FOOTER', FriendlyLinkList.code != '0' ? FriendlyLinkList.data.list : [])
    },
    getMemberInfoFn({ commit }, params) {
        return APIS.getMemberInfo(params)
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == '1') {
                        // console.log('用户信息', data)
                        commit('SET_USER_DATA', data.memberInfo);
                    }
                    else {
                        let memberInfo9 = { vipInfo: {} }
                        commit('SET_USER_DATA', memberInfo9);
                        Toast.fail(res.msg);
                        // Toast.fail('请退出，重新登入');
                    }
                    return res;
                }
            });
    },
    getMoneyRefreshFn({ commit }, params) {
        return APIS.getMoneyRefresh(params)
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == '1') {
                        commit('SET_MONEY_REFRESH', data);
                    }
                    return res;
                }
            });
    },
    // 排行榜
    getGiftTop3Fn({ commit }, params) {
        return APIS.getGiftTop3(params)
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == '1') {
                        commit('SET_GIFT_TOP3', data.listGift || []);
                    }
                    // else {
                    //     Toast.fail(res.msg);
                    // }
                    return res;
                }
            });
    },
    // vip座驾特效
    getCarEffectListFn({ commit }, params) {
        return APIS.getCarEffectList(params)
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == '1') {
                        commit('SET_CAR_EFFECTLIST', data.listGift || []);
                    }
                    else {
                        Toast.fail(res.msg);
                    }
                    return res;
                }
            });
    },
    // 关闭特效
    getIsEffectsFn({ commit }, params) {
        commit('SET_IS_EFFECTS', params);
    },
    // 全屏开关
    getIsLivePageFn({ commit }, params) {
        commit('SET_IS_LIVEPAGE', params);
    },

};

// getters
const getters = {
    M_FooterList(state) {
        return state.footerList
    },
    M_token(state) {
        return state.token
    },
    M_USER_DATA(state) {
        return state.userInfoData
    },
    M_chatData(state) {
        return state.chatData
    },
    M_userActiveIndex(state) {
        return state.userActiveIndex
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
