<template>
  <div
    class="emotion-box"
    id="emotion-box"
    :style="{ height: height + 'px' }"
    ref="wrapper2"
  >
    <div class="emotion-ctn">
      <div class="emotion-box-line" v-for="(line, i) in list" :key="i">
        <emotion
          class="emotion-item"
          v-for="(item, i) in line"
          :key="i"
          @click.native="clickHandler(item)"
          >{{ item }}</emotion
        >
      </div>
    </div>
  </div>
</template>

<script>
import emotion from "./emoting";
import emojiData from "./emoji.json";
import GetUtils from "../../utils/utils";
// import BScroll from '@better-scroll/core';

export default {
  name: "ly-emotionIndex",
  props: {
    height: { type: Number, default: 260 },
  },
  data() {
    return {
      list: emojiData.emojiSort,
    };
  },
  mounted() {
    // this.$nextTick(() => {
    // this.scroll = new BScroll(this.$refs.wrapper2, {
    //   scrollY: true,
    //   click: true,
    //   probeType: 3,
    // });
    GetUtils.touchScroll("emotion-box");
    // })
  },
  methods: {
    clickHandler(i) {
      let emotion = `[${i}]`;
      this.$emit("emotion", emotion);
    },
  },
  components: {
    emotion,
  },
};
</script>
<style lang="scss">
.emotion-box {
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0;
  border: 1px solid transparent;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;

  .emotion-box-line {
    display: flex;
    margin: 0 0 10px 0;
  }
  .emotion-item {
    flex: 1;
    text-align: center;
    cursor: pointer;
    img {
      width: 24px;
    }
  }
}
</style>