<template>
  <div class="live-chat-room">
    <div class="lcr-head" v-if="Object.keys(anchormanInfo).length > 0 && Object.keys(chatlist).length > 0">
      <chat-room
        :liveData="anchormanInfo"
        :liveId="anchormanInfo.liveCtId"
        :userData="anchormanInfo"
        :chatlist="chatlist"
        :sendDame="sendDame"
      ></chat-room>
    </div>
  </div>
</template>

<script>
import ChatRoom from "../../components/LiveChatRoom/Msg.vue";

export default {
  data() {
    return {
      active: "0",
      anchorShow: false,
      anchormanLiveVo: {},
      liveData: {},
    };
  },
  props: ["anchormanRealList", "anchormanInfo", "chatlist", "liveList","sendDame"],
  mounted() {
    this.anchormanLiveVo = this.liveList.anchormanLiveVo;
    this.liveData = this.anchormanInfo;
  },
  methods: {

  },
  computed: {
    isShowFoot() {
      var show;
      if (this.anchormanInfo.isfoot == 1 || this.anchormanInfo.isfoot == 2)
        show = true;
      else show = false;
      return { show, isfoot: this.anchormanInfo.isfoot };
    },
    barrageMsgliveData() {
      let obj = {};
      if (this.liveData.memberId) {
        obj = this.liveData;
      } else {
        obj = null;
      }
      return obj;
    },
  },
  components: {
    ChatRoom,
  },
};
</script>

<style lang="scss">
.live-chat-room {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.lcr-head {
  position: relative;
  .live-hot-icon {
    width: 10px;
    margin-right: 3px;
  }
  .live-hot-size {
    font-size: 12px;
    color: #999999;
  }
  .download-btn {
    background: linear-gradient(270deg, #22bd82 0%, #1de498 100%);
    border: transparent;
    padding: 7px 30px;
    height: auto;
    border-radius: 30px;
  }
  .van-tab {
    flex: auto;
  }
  .van-tab:nth-of-type(1) {
    flex-basis: 36px;
  }
  .van-tab:nth-of-type(2) {
    justify-content: left;
    overflow: hidden;
  }
}
.lcr-anchor-info {
  position: fixed;
  bottom: 80px;
  right: 0;
  img {
    border: #22bd82 2px solid;
    border-radius: 100px;
  }
}
.lcr-anchor-container {
  position: absolute;
  left: 0;
  width: 100%;
  .room-number,
  .league_list {
    font-size: 12px;
  }
  .league_list {
    &:nth-of-type(1) {
      background: #fa6400 !important;
    }
    &:nth-of-type(2) {
      background: #ff4a53 !important;
    }
    &:nth-of-type(3) {
      background: #6236ff !important;
    }
    &:nth-of-type(n + 4) {
      display: none;
    }
  }
  .anchor-fans {
    font-size: 12px;
  }
  .room-notice {
    font-size: 12px;
  }
}
</style>