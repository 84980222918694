<template>
  <div class="wonder-player">
    <video
      class="one-player"
      id="player-container-wonder"
      preload="auto"
      playsinline
      webkit-playsinline
    ></video>
  </div>
</template>

<script>
// 回播播放
import TCPlayer from "tcplayer.js";
import "tcplayer.js/dist/tcplayer.min.css";
export default {
  props: {
    isWonderVoice: {
      type: Boolean,
      default: false
    },
    videoUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      playerTx: undefined,
    };
  },
  mounted() {
    // this.$bus.on("play-video", this.playVideo);
    // this.$bus.on("toggle-video", this.toggleVideo);
    this.playVideo()
  },
  watch: {
    isWonderVoice(val) {
      if(val) {
        this.playerTx.muted(false)
        this.playerTx.volume(100);
      }
    }
  },
  beforeDestroy() {
    this.playerTx.dispose()
  },
  methods: {
    initPlayer() {
      this.playerTx = TCPlayer("player-container-wonder", {
        autoplay: true,
        reportable: false,
        controls: false,
        muted: true,
        loop: true,
        preload: 'auto'
      });
    },
    playVideo() {
      let url = this.videoUrl
      if (!this.playerTx && url) {
        this.initPlayer();
      }
      if (url) {
        this.playerTx.src(url);
      }
      // if (this.playerTx) {
      //   this.playerTx.play();
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.wonder-player {

}
.one-player {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 2;
  top: 0;
}
</style>