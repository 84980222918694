import Vue from 'vue';
import App from './App.vue';
import './assets/reset.css';  // 引入基本样式
import 'vant/lib/index.css';
import store from './store';
import homeApi from './http/homeApi';
import vant from 'vant';
import VueClipboard from 'vue-clipboard2';
import { register } from 'swiper/element/bundle';
register();
import VueBus from 'vue-bus';

Vue.use(VueBus);

// lottie-player 动画
// import "@lottiefiles/lottie-player";

Vue.prototype.$homeApi = homeApi
Vue.config.productionTip = false

Vue.use(vant)
Vue.use(VueClipboard)

// uat环境调试工具
// import VConsole from 'vconsole'
// let environment = process.env.VUE_APP_MODE;
// if(environment != 'production'){
//     const vConsole = new VConsole();
//     vConsole.setSwitchPosition(20, 200);
// }

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')


