<template>
  <div class="home" :class="{ black: mode == 'black' }">
    <div class="home_bj">
      <img :src="liveList.anchormanLiveVo.pic || require('@/assets/images/live_bj.jpg')"
        v-if="Object.keys(anchormanInfo).length > 0 && Object.keys(liveList).length > 0" :class="[isLivePage ? 'img02': 'img01']" alt="bg" v-on:error.once="errorImg($event)"/>
      <img src="@/assets/images/live_bj.jpg" v-else class="img02" alt="bg" v-on:error.once="errorImg($event)"/>
      <div class="vido_ctn" v-if="anchormanInfo.status == 1">
        <WonderPlayer ref="WonderPlayer" :videoUrl="anchormanInfo.video"  :isWonderVoice="isWonderVoice"
        v-if="isLivePage && anchormanInfo.video && anchormanInfo.video.length > 0"/>
        <LiveVideo
          ref="liveVideo"
          :liveList="liveList"
          :anchormanInfo="anchormanInfo"
          :anchormanRealList="anchormanRealList"
          :isVoice="isVoice"
          :dameMessg="dameMessg"
          @resetVideo="resetVideo"
          v-if="!isLivePage"
        ></LiveVideo>
      </div>
      <div v-else>
        <RecapPlayer/>
      </div>
    </div>
    <!-- 精彩视频声音按钮 -->
    <div class="wonder_volum" @click="handWonderVolum()" v-if="isLivePage && !isWonderVoice && anchormanInfo.video && anchormanInfo.video.length > 0">
      <img :src="isWonderVoice? require('../../assets/images/close_2.png'): require('../../assets/images/close.png')" alt="close" class="btn"/>
    </div>
    <div class="btn_volume" @click="handVolume()" v-if="(!isLivePage && !isVoice) && isEnd">
      <img :src="isVoice? require('../../assets/images/close_2.png'): require('../../assets/images/close.png')" alt="close" class="btn"/>
    </div>
    <section class="live-container" @click="handWonderVolum()" v-if="anchormanInfo.status == 1">
      <onePage v-if="isLivePage"></onePage>
      <div class="twoPage" v-else @click.stop="handVolume2()" :class="isLivePage ? 'fade_out': 'fade_in'">
        <twoPage
        :sendDame="sendDame"
        :closeVol="closeVol"
        :anchormanInfo="anchormanInfo"
        :anchormanRealList33="anchormanRealList"
        @getIsEnd="getIsEnd"
        >
        </twoPage>
      </div>
    </section>
    <section class="recap-container" :class="{'slide-out': !(anchormanInfo.status != 1 && showRecapList)}">
      <div class="playing-event" @click="keepPlayFunc" v-show="recapIsPaused">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path d="M75.459 21.2598C75.459 22.0912 75.7903 22.8885 76.38 23.4764C76.9697 24.0643 77.7695 24.3945 78.6035 24.3945C79.4375 24.3945 80.2373 24.0643 80.827 23.4764C81.4167 22.8885 81.748 22.0912 81.748 21.2598C81.748 20.4284 81.4167 19.631 80.827 19.0432C80.2373 18.4553 79.4375 18.125 78.6035 18.125C77.7695 18.125 76.9697 18.4553 76.38 19.0432C75.7903 19.631 75.459 20.4284 75.459 21.2598Z" fill="white"/>
          <path d="M40.6055 66.3965C41.1816 66.3965 41.7285 66.2402 42.1875 65.957L64.7559 52.8125C65.8203 52.3047 66.5625 51.2207 66.5625 49.9609C66.5625 48.7988 65.9375 47.7832 65 47.2461L42.9395 34.3945C42.373 33.7402 41.5332 33.3301 40.6055 33.3301C38.8867 33.3301 37.5 34.7363 37.5 36.4648C37.5 36.5234 37.5 36.582 37.5098 36.6406L37.4707 36.6602L37.5195 62.9297C37.5098 63.0371 37.5 63.1445 37.5 63.2617C37.5 64.9902 38.8965 66.3965 40.6055 66.3965Z" fill="white"/>
          <path d="M88.8477 29.9414C88.3203 28.916 87.2559 28.2031 86.0254 28.2031C84.2871 28.2031 82.8809 29.6094 82.8809 31.3379C82.8809 31.9238 83.0469 32.4805 83.3301 32.9492L83.3203 32.959C85.9473 38.0762 87.4316 43.877 87.4316 50.0195C87.4316 70.6836 70.6738 87.4414 50.0098 87.4414C29.3457 87.4414 12.5781 70.6836 12.5781 50.0195C12.5781 29.3555 29.3359 12.6074 50 12.6074C56.1035 12.6074 61.8652 14.0723 66.9531 16.6602L66.9727 16.6211C67.4219 16.875 67.9492 17.0215 68.5059 17.0215C70.2441 17.0215 71.6504 15.6152 71.6504 13.8867C71.6504 12.6074 70.8789 11.5039 69.7656 11.0156C63.8281 7.99805 57.1191 6.29883 50 6.29883C25.8496 6.29883 6.2793 25.8789 6.2793 50.0195C6.2793 74.1602 25.8496 93.7402 50 93.7402C74.1504 93.7402 93.7207 74.1699 93.7207 50.0195C93.7207 42.7832 91.9629 35.957 88.8477 29.9414Z" fill="white"/>
        </svg>
      </div>
      <div class="video_info" v-if="anchormanInfo.status == 2">
        <span class="live_icon">直播预告</span>
        <p class="live_name"><span class="text">{{ timestampToTime(anchormanInfo.startTime) }}</span></p>
        <p class="live_desc"><span class="text">{{ anchormanInfo.preTitle }}</span></p>
      </div>
      <div class="recap-list-container" v-if="recapList.length > 0">
        <p class="recap-title">往期回顾</p>
        <swiper-container class="recap-list" :slides-per-view="3" :space-between="10" :observer="true" :observe-parents="true" :observe-slide-children="true" :navigation="true" allow-touch-move="false">
          <swiper-slide v-for="(item, index) in recapList" :key="index">
            <div class="recap-item" :class="{'current-playing': index == currentPlaying}" @click.stop="toPlayFunc(item, index)">
              <img :src="item.coverPic || require('@/assets/images/live_bj.jpg')" v-on:error.once="errorImg($event)" :alt="item.title">
              <div class="recap-info">
                <p class="live-time">{{timestampToTime(item.startTime)}}</p>
                <p class="live-title">{{item.title}}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </section>
  </div>
</template>

<script>

import GetUtils from "../../utils/utils";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import { Toast, Dialog } from "vant";

import onePage from './onePage.vue';
import twoPage from './twoPage.vue';
import LiveVideo from "@/components/LiveVideo/index";
import WonderPlayer from "@/components/LiveVideo/wonder-player";
import RecapPlayer from "@/components/recapPlayer";


export default {
  name: "HomeHeader",
  components: { onePage, LiveVideo, twoPage, RecapPlayer, WonderPlayer },
  data() {
    return {
      player: null,
      anchormanInfo: {}, // 主播信息 status:0 关闭(需要用getAnchormanInfo接口返回code2处理关闭) 1:直播中 2:预告
      liveList: {}, // 直播数据
      anchormanRealList: {}, // 直播状态数据
      token: null,
      isBufferPlay: true, //是否加载完毕
      mode: "",
      initialNumber: 0,
      isVol: false,
      isLoading: true, // 加载中
      isShowVideo: true,
      isVoice: false,
      isWonderVoice: false,
      dameMessg:'',
      isEnd: true,
      recapList: [], // 回播列表
      currentPlayId: '', // 当前在播放的id
      showRecapList: true, // 是否显示回播列表
      recapTimer: null, // 回播点击后多久隐藏列表
      recapIsPaused: false, // 是否正在暂停中
      currentPlaying: -1, // 当前正在播放
    };
  },
  async created() {
    this.initialData();
  },
  computed: {
    // 取值
    ...mapState({
      isLivePage: (state) => state.home.isLivePage,
    }),
  },
  // watch: {
  //   "anchormanRealList.live": function () {
  //     //聊天室初始化播放器
  //     let that = this;
  //     this.$nextTick(() => {
  //       if (that.anchormanRealList.status == 1) {
  //         that.Initplayer();
  //         // 在播放中就不显示
  //         that.player.on("play", function () {
  //           that.isBufferPlay = false;
  //         });
  //         that.player.on("canplay", function () {
  //           that.isBufferPlay = false;
  //         });
  //         that.player.on("ready", function () {
  //           setTimeout(() => {
  //             if (that.isBufferPlay) {
  //               // that.$router.go(0);
  //             }
  //           }, 20000);
  //         });
  //       }
  //     });
  //   },
  // },
  mounted() {
    this.$bus.emit('page-ready', true);
    this.$bus.on('toggle-list', this.toggleList);
    this.$bus.on('reset-voice', this.closeVol);
    this.mode = GetUtils.getUrlKey("mode");
  },
  // destroyed() {
  //   if (this.player) {
  //     this.player.hls.destroy();
  //     this.player.destroy();
  //   }
  // },
  methods: {
    ...mapMutations({
      M_TOKEN: "M_TOKEN",
      SET_MEMBER_ID: "SET_MEMBER_ID",
    }),
    errorImg(e) {
      e.currentTarget.src = require('@/assets/images/live_bj.jpg');
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear();
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1);
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours());
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds(): date.getSeconds();
      return Y + "-" + M +  "-" + D + " " + h + ":" + m + ":" + s;
    },
    async initialData() {
      this.isLoading = true;

      let res = await this.$homeApi.postRequest({
        apiName: "getAnchormanInfo",
        memberId: 1,
      });
      if (res && res.code == '1' && res.data) {
        this.anchormanInfo = res.data;
        this.isLoading = false;

        let _this = this;
        if (Object.keys(this.anchormanInfo).length > 0) {
          let [
            liveList1,
            anchormanRealData2,
          ] = await Promise.all([
            _this.$homeApi.postRequest({
              apiName: "getAnchormanLive",
              liveCtId: _this.anchormanInfo.liveCtId,
            }),
            _this.$homeApi.postRequest({
              apiName: "anchormanRealData",
              memberId: 1,
            }),
          ]);

          // 直播数据
          if (liveList1.code == "1" && liveList1.data) {
            this.liveList = liveList1.data ? liveList1.data : {}
          }
          // 直播实时数据查询
          if (anchormanRealData2.code == "1" && anchormanRealData2.data) {
            this.anchormanRealList = anchormanRealData2.data;
          }
          // 不是直播时候获取回播列表
          if(this.anchormanInfo.status != 1) {
            this.getRecapList();
          }
        }
      }
      else if (res && res.code == '2') {
        this.isLoading = false;
        this.getRecapList();
        return false;
      }
      else {
        this.initialNumber++;
        if (this.initialNumber < 3) {
          this.initialData();
        } else {
          this.getErrorDialog();
        }
      }

    },
    resetVideo() {
      this.isShowVideo = false;
      setTimeout(() => {
        this.isShowVideo = true;
      }, 1000);
    },
    sendDame(e) {
      //发送弹幕
      this.dameMessg = e;
      // this.$refs.liveVideo.sendDamu(e);
    },
    closeVol(val){
      this.isWonderVoice = val;
      this.isVoice = val;
    },
    getIsEnd(val){
      this.isEnd = val;
    },
    handVolume() {
      this.isVoice = true;
      // this.isVol = !this.isVol;
      // if (this.isVol) {
      //   this.player.video.muted = false;
      //   this.player.volume = 1;
      // } else {
      //   this.player.video.muted = true;
      //   this.player.volume = 0;
      // }
    },
    handVolume2 () {
      this.isVoice = true;
    },
    handWonderVolum() { //只在预览视频界面触发声音控件
      if(this.isLivePage) {
        this.isWonderVoice = true
      }
    },
    getErrorDialog() {
      Dialog.alert({
        message: '网络异常，请稍后再试',
        theme: "round-button",
      }).then(() => {
        // on close
        // 重新刷新
        return false
      });
    },

    enterLive() {
      let url = window.location.href;
      if (url.indexOf("?") != -1) {
        url = url.split("?")[0] + "myRoom";
      } else {
        url = url + "myRoom";
      }

      if (!this.anchormanInfo.live) {
        return false;
      }

      // 进入直播之前调用APP方法
      if (GetUtils.getUrlKey("isRn") == 1) {

        // app 是否登录状态
        window.postMessage(
          JSON.stringify({
            type: "onCheckLoginStatus",
          })
        );
        // 跳到全屏直播
        window.postMessage(
          JSON.stringify({
            type: "onOpenLivePage",
            roomUrl: url,
          })
        );
      }

      // 安卓 app 是否登录状态
      if (window?.AndroidClient.onCheckLoginStatus || false) {
        window?.AndroidClient.onCheckLoginStatus();
      }
      // 唤起全屏直播
      if (window?.AndroidClient.onOpenLivePage || false) {
        window?.AndroidClient.onOpenLivePage(url);
      }

    },
    toPlayFunc(item, index) {
      this.currentPlaying = index;
      if(this.currentPlayId != item.id) {
        this.currentPlayId = item.id;
        this.$homeApi.postRequest({
          apiName: "visitAnchormanHistory",
          memberId: 1,
          id: item.id
        });
      }
      this.$bus.emit('play-video', item.videoUrl);
      this.recapListTimerCheck();
    },
    async getRecapList() {
      let res = await this.$homeApi.postRequest({
        apiName: "getAnchormanHistory",
        memberId: 1,
      });
      if (res && res.code == '1' && res.data) {
        this.recapList = res.data.histories;
      }
    },
    recapListTimerCheck() {
      if(this.recapTimer) {
        clearTimeout(this.recapTimer)
        this.recapTimer = null;
      }
      this.recapTimer = setTimeout(()=>{
        this.showRecapList = false;
        clearTimeout(this.recapTimer)
        this.recapTimer = null;
      }, 3000)
    },
    toggleList(status) {
      // if(status) {
        this.showRecapList = status;
        this.recapIsPaused = status;
      // }
    },
    keepPlayFunc() {
      this.$bus.emit('toggle-video');
      clearTimeout(this.recapTimer)
      this.recapTimer = null;
      if(this.recapIsPaused) {
        this.showRecapList = true;
      } else {
        this.showRecapList = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  .wonder_volum {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 80;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      display: block;
      width: 33px;
    }
  }
  .btn_volume {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 130px;
    left: 0;
    z-index: 80;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      display: block;
      width: 33px;
    }
  }
  .twoPage{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &.fade_in{
          animation: fadeIn01 cubic-bezier(0.22, 0.58, 0.12, 0.98) 1s;
      }
    &.fade_out{
          animation: fadeOut01 cubic-bezier(0.22, 0.58, 0.12, 0.98) 1s;
      }
  }

  .home_bj {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
    // .home_bj_img {
    //   width: 100%;
    //   height: 100%;
    // }
    .img01 {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(8px);
    }

    .img02 {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .vido_ctn {
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 2;
    }

  }

  .recap-container{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    position: relative;
    z-index: 2;
    transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
    &.slide-out{
      transform: translateY(100%);
      opacity: 0;
    }
    .playing-event{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 60px;
    }
    .recap-list-container{
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.00) 100%);
      padding-bottom: 11px;
      .recap-title{
        color: #fff;
        font-size: 15px;
        padding: 5px 10px;
      }
      .recap-list{
        padding: 0 10px;
        .recap-item{
          &.current-playing{
            border: 2.5px solid #fff;
          }
          position: relative;
          // max-width: 140px;
          height: 140px;
          border-radius: 8px;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: sub;
          }
          .recap-info{
            position: absolute;
            bottom: 0;
            width: 100%;
            color: #fff;
            font-size: 10px;
            padding: 4px 6px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.00) 100%);
          }
        }
      }
    }
  }

  .main {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .home_video {
    margin-top: 100px;


    .video_centent {
      width: 100vw;
      // height: 56.27vw;
      height: 80vw;



      ::v-deep .xgplayer-controls {
        background-image: none !important;
        bottom: 20px;
      }

      // ::v-deep .xgplayer-live {
      //   display: none !important;
      // }
      ::v-deep .xgplayer-fullscreen {
        display: none !important;
      }

      ::v-deep .xgplayer-play {
        margin-left: 15px;
      }

      ::v-deep .xgplayer-volume {
        margin-right: 10px !important;
      }

      ::v-deep .xgplayer-start {
        background: none !important;
      }


    }

    .enter_live {
      width: 164px;
      height: 38px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 31px;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 40px;
      transition: all 1ms;

      &:active {
        background: #e6e6e6;
      }
    }
  }

  .video_info {
    padding-left: 16px;
    font-size: 14px;
    .live_icon {
      height: 22px;
      background: #ff5858;
      border-radius: 3px;
      text-align: center;
      color: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      padding: 5px;
      padding-bottom: 6px;
    }

    .live_name {
      color: #fff;
      margin-bottom: 10px;
      .text{
        border-radius: 4px;
        padding: 2px 6px;
        background: rgba(0, 0, 0, 0.30);
      }
    }

    .live_desc {
      color: #fff;
      margin-bottom: 16px;
      .text{
        border-radius: 4px;
        padding: 2px 6px;
        background: rgba(0, 0, 0, 0.30);
      }
    }
  }
}

.black {
  background: #000;

  .enter_live {
    background: #2e2e2e !important;
    color: #c7c7c7 !important;
    border: none !important;
  }

  .live_name {
    color: #fff !important;
  }

  .live_desc {
    color: #dedede !important;
  }
}
@keyframes fadeIn01 {
    0% {
        opacity: 0;

    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeOut01 {
    0% {
        opacity: 1;

    }
    100% {
        opacity: 0;
    }
}


// 8
@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {
  .home .home_video {
    margin-top: 1.8rem;
  }



}
.live-container {
  z-index: 9;
  position: relative;
  height: 100vh;
}
</style>