<template>
  <div class="ly-emotion">
    <slot></slot>
  </div>
</template>

<script>
import emojiData from "./emoji.json";

export default {
  name: "ly-emotion",
  mounted() {
    const name = this.$el.innerHTML;
    const list = emojiData.emojiList;
    let index = list.indexOf(name);
    let imgHTML = "<img src="+require('../../assets/emojipng/'+list[index]+'.png')+">";
    this.$nextTick(() => {
      this.$el.innerHTML = imgHTML;
    });
  }
};
</script>
<style scoped>
    .ly-emotion {
    display: inline-block;
    }
    .ly-static-emotion {
    width: 24px;
    height: 24px;
    display: inline-block;
    }
</style>
