<template>
  <div class="chat_box" :class="calculativeWidth">
    <!-- ---------------------------礼物赠送----------------------------------- -->
    <div
      class="gift_animation animate__animated animate__slideInLeft"
      v-if="giftAnimShow && isEffects"
    >
      <div class="head">
        <img
          :src="
            giftAnimData.fromPhoto
              ? giftAnimData.fromPhoto
              : require('../../assets/images/avatar2.png')
          "
          alt=""
          class="headImg"
        />
        <img
          :src="getLevlIcon(giftAnimData.vipLevel)"
          class="vm_giftIcon"
          v-if="giftAnimData.vipLevel !== 0"
        />
      </div>
      <span class="v_m11">
        <span class="v_top">
          <span class="f14 db">{{ giftAnimData.nickName }}</span>
        </span>
        <span class="f12 db"
          >送出
          <span class="giftName">{{ giftType(giftAnimData.ct) }}</span>
        </span>
      </span>
      <span class="v_m22">
        <img :src="getGiftImg(giftAnimData.ct)" alt="" />
        <!-- <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_UJNc2t.json"
          background="transparent"
          speed="1"
          style="width: 50px; height: 50px" autoplay>
        </lottie-player> -->
      </span>
      <span class="v_numb">
        <i class="v_numb_animat animate__animated myFadeIn"
          >x{{ giftAnimData.giftNum }}</i
        >
      </span>
    </div>
    <!-- 房间 -->
    <div class="chat_box_content" id="chatRecord" ref="wrapper">
      <div class="massge_ctn" v-if="chatData">
        <!-- 公告 -->
        <div class="announcement">
          <span class="title">公告</span>
          <span class="tips">{{ liveData ? liveData.desc : "" }}</span>
        </div>

        <!-- 历史消息 -->
        <template v-for="item in chatData.userInfoList">
          <p v-if="item.ctType == 1 || item.ctType == 10" :key="item">
            <img class="user_head v_m" :src="item.photo" />
            <span class="name_color">{{ item.nickName }}:</span>
            <span
              class="ct"
              :class="{ orange: item.ctType == 10 }"
              v-if="item.ct"
              v-html="item.ct.replace(/(\[ht[a-z]\d{2}\])/g, emotionImgSrc)"
            >
            </span>
          </p>
        </template>
        <!-- 实时聊天消息 -->
        <template v-for="(item, i) in jobLists">
          <div
            v-if="item.ctType == 1 || item.ctType == 10"
            :key="'a' + i"
            class="child_time"
          >
            <p class="timeMessage" :class="setClassFn(item)">
              <img
                :src="getLevlIcon(item.vipLevel)"
                class="timeImg"
                v-if="item.vipLevel !== 0 && !item.anchorman"
              />
              <img
                src="@/assets/image_2/icon/author_icon.png"
                class="timeImg"
                v-if="item.anchorman"
              />
              <span class="messageRight" :class="getDoubleClass(item.ct, '')">
                <span
                  slot="reference"
                  class="name_color"
                  :class="getLevlClass(item)"
                >
                  {{ trim(item.nickName) }}：
                </span>
                <span v-if="item.ctType == 10" class="laba"
                  ><img src="../../assets/images/icon3.png" alt=""
                /></span>
                <span
                  class="ct"
                  :class="getDoubleClass(item.ct, item.ctType)"
                  v-html="item.ct.replace(/(\[ht[a-z]\d{2}\])/g, emotionImgSrc)"
                >
                </span>
              </span>
            </p>
          </div>
          <div v-if="item.ctType == 3" :key="'b' + i" class="child_time">
            <p class="giftMessage">
              <img
                :src="getLevlIcon(item.vipLevel)"
                class="giftIcon"
                v-if="item.vipLevel !== 0"
              />
              <span class="c_r">
                <span class="name_color" :class="getLevlClass(item)">
                  {{ trim(item.nickName) }}：
                </span>
                送出 {{ giftType(item.ct) }}
                <span class="msg_pic"
                  ><img class="giftimg" :src="getGiftImg(item.ct)" width="22"
                /></span>
                <i class="liwu">x{{ item.giftNum }}</i>
              </span>
            </p>
          </div>
          <div v-if="item.ctType == 4" :key="'c' + i" class="child_time">
            <p class="welcomeMessage">
              <span class="name_color">主播：</span>
              <span class="name_text orange"
                >欢迎 {{ item.nickName }} 大哥来捧场～</span
              >
            </p>
          </div>
          <div v-if="item.ctType == 9" :key="'d' + i" class="child_time">
            <p class="systemMessage twoShow">
              <span class="name_color">{{ item.nickName }}：</span>
              <span class="ct orange" v-html="item.ct"></span>
            </p>
          </div>
        </template>
      </div>
    </div>

    <!-- vip -->
    <div class="welcomeVip_ctn" v-show="isEffects">
      <div
        id="welcomeVip_1"
        v-if="welcomeNickName2"
        ref="welcomeVip_"
        class="welcome welcomeVip_1"
        :class="
          welcomeTxtShow2
            ? 'animate__animated animate__slideInRight'
            : 'animate__animated animate__slideOutLeft'
        "
      >
        <p class="welcome_txt" :class="[welcomeLevel2 >= 3 ? 'vip3' : 'right']">
          <span class="c_font">
            <div class="toux">
              <img
                :src="
                  fromPhoto2
                    ? fromPhoto2
                    : require('../../assets/images/avatar2.png')
                "
                alt=""
                class="tou"
              />
              <img
                :src="getLevlIcon(welcomeLevel2)"
                v-if="welcomeLevel2 !== 0 && !welcomeAnchorman"
                class="vip"
              />
              <img
                src="@/assets/image_2/icon/author_icon.png"
                v-if="welcomeAnchorman"
                class="vip"
              />
            </div>
            <span
              class="name_color"
              :class="[welcomeLevel2 > 11 ? 'l11-14' : '']"
            >
              {{ welcomeNickName2 }} </span
            >光临直播间
          </span>
        </p>
      </div>
    </div>

    <!-- 普通欢迎 -->
    <div class="webcast_welcome">
      <div>
        <p
          class="welcome_txt2"
          v-if="welcomeNickName && welcomeTxtShow"
          :class="welcomeTxtShow ? 'move_in_top' : 'move_in_off'"
        >
          <span class="c_font">
            <span class="name_color">
              <img :src="getLevlIcon(welcomeLevel)" v-if="welcomeLevel !== 0" />
              {{ welcomeNickName }} </span
            >光临直播间
          </span>
        </p>
      </div>
    </div>

    <p class="toBottom" v-if="newMess != 0" @click="toBottom">
      <span>
        有{{ newMess }} <i v-if="newMess == 99" class="p0">+</i>条新消息
        <img src="../../assets/images/arrow@2x.png" alt width="12" />
      </span>
    </p>

    <div class="send_box clear">
      <div
        class="login_tip f12"
        v-if="roomHotList && roomHotList.roomHot && userData.live"
      >
        {{ roomHotList.roomHotTxt }}
      </div>
      <!-- <div class="login_tip" v-else-if="!M_token">
        请先
        <a class="cur c_r" @click="login">登录</a>
      </div> -->
      <div class="login_tip no_talk-box" v-if="jinyan">
        <p class="no_talk">
          <marquee scrollamount="3">{{ jinyanTxt }}</marquee>
        </p>
      </div>

      <div class="footer">
        <p class="btn_text" @click.stop="handChatShow()">说点什么...</p>

        <div @click="handGiftsOpen" class="giftbtn">
          <img src="../../assets/images/Vector.png" alt="" />
        </div>
        <div class="btn_off" @click.stop="handOffClick()">
          <img
            :src="
              isUrlImg
                ? require('../../assets/images/off.png')
                : require('../../assets/images/off2.png')
            "
            alt=""
          />
        </div>
        <div class="btn_off" @click.stop="switchChatFunc()">
          <img src="../../assets/images/clear-on.svg" alt="" />
        </div>
        <div class="btn_share" @click.stop="handShare()">
          <img src="../../assets/images/share.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 礼物打赏 -->
    <giftGiving
      :giftShowDialog="giftShowDialog"
      :roomId="chatData.roomId"
      @handGiftColse="handGiftColse"
    />

    <!-- 聊天 -->
    <van-popup
      v-model="chatShow"
      close-icon-position="top-left"
      position="bottom"
      :style="{ height: 'auto' }"
    >
      <div class="chatroom">
        <van-tabbar v-model="chatType" @change="onChange">
          <van-tabbar-item class="iconRight">
            <template #icon="">
              <img :src="chatType == 0 ? icon.active : icon.inactive" />
            </template>
          </van-tabbar-item>
          <van-tabbar-item>
            <template #icon="">
              <img :src="chatType == 1 ? icon2.active : icon2.inactive" />
            </template>
          </van-tabbar-item>
        </van-tabbar>

        <div class="send_box22">
          <div class="left">
            <form action="javascript:;">
              <input
                type="text"
                v-model="ct"
                autocomplete="off"
                :placeholder="
                  chatType == 0
                    ? '请输入想和主播说的话...'
                    : `已开启付费弹幕，${chatData.talkPay}金币/条 ...`
                "
                ref="chatinput"
                id="emojiInput"
                class="inputsend"
                maxlength="31"
                @blur="fixBlur()"
                @keyup.enter="searchEvent"
                @keydown="handleInputKeydown"
              />
            </form>
            <span
              class="icon1 emoji_btn"
              :class="emojiShow ? 'cative' : ''"
              @click="emojiBtn"
              id="emoji"
            ></span>
          </div>
          <div class="btn_send">
            <div class="btn_send_ctn" @click.stop="sendCt()">
              <img class="icon" src="../../assets/images/send.png" alt="发送" />
            </div>
          </div>
        </div>
        <emotion
          v-if="!jinyan"
          @emotion="handleEmotion"
          :class="{ 'emoji-box': setStyle }"
          :height="260"
          v-show="emojiShow"
          class="emoji_box"
        >
        </emotion>
      </div>
    </van-popup>

    <van-overlay :show="showDialog">
      <div class="wrapper2">
        <div class="block">
          <div class="header">确认发送付费弹幕？</div>
          <div class="footer">
            <div
              class="btnleft"
              :class="btnOkVale == 1 ? 'active' : ''"
              @click="handBtnOk(1)"
            >
              取消
            </div>
            <div
              class="btnright"
              :class="btnOkVale == 2 ? 'active' : ''"
              @click="handBtnOk(2)"
            >
              确认
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 礼物特效 -->
    <div class="effects_box" id="demoCanvas" v-show="isEffects"></div>
    <!-- 高级用户特效 -->
    <div class="high_level" id="high_level" v-show="isEffects"></div>
    <!-- <div class="btncs" @click="addHight()">测试特效</div> -->
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import GetUtils from "../../utils/utils";
import { Toast, Dialog } from "vant";
import emojiData from "../emotion/emoji.json";
import emotion from "../emotion/index.vue";
import SVGA from "svgaplayerweb";

import giftGiving from "../giftGiving/index.vue";
import "./msg.scss";
import BScroll from "@better-scroll/core";

export default {
  data() {
    return {
      ggshow: false,
      giftAnimShow: false,
      chatData: {},
      ct: "", // 聊天内容
      preMsg: "", // 兼容iOS重复消息问题
      messageList: [],
      disabled: true,
      memberInfo: {},
      // websocket
      websock: null,
      ws: null, //建立的连接
      lockReconnect: false, //是否真正建立连接
      timeout: 30 * 1000, //30秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      welcomeList1: [],
      welcomeNickName: "",
      welcomeLevel: null,
      welcomeTxtShow: false,
      welcomeIs: false,

      welcomeListVip: [],
      welcomeNickName2: "",
      fromPhoto2: "",
      welcomeLevel2: null,
      welcomeTxtShow2: false,
      welcomeIs2: false,
      welcomeAnchorman: false,

      newMess: 0,
      scrollTop: 0,
      fontSize: false,
      jinyan: false,
      jinyanTxt: "",
      //弹幕表情
      emojiShow: false,
      setStyle: false,
      // 礼物打赏
      giftAnimList: [],
      giftAnimIs: false,
      giftShowDialog: false,

      chatShow: false,
      chatType: 0,
      icon: {
        active: require("../../assets/images/icon5.png"),
        inactive: require("../../assets/images/icon4.png"),
      },
      icon2: {
        active: require("../../assets/images/icon3.png"),
        inactive: require("../../assets/images/icon2.png"),
      },
      showDialog: false,
      btnOkVale: 2,
      payTalkKey: "",
      giftList: [], //礼物列表
      giftListIs: false,

      limitTimeShow: true, // 限制时间
      limitTimeNumb: 0,
      limitTimer: null,
      msk: false,
      timerMsk: null,
      welcomeAnim: true,
      time22: null,
      jobListsCache: [], // 缓存
      jobLists: [],
      jobNumber: 100,
      isUrlImg: true,
      highLevelArr: [], //高级特性进场
      highLevelIs: false,
      highLevelInde: 0,
      carEffectList: [],
      WssUrl: "",
    };
  },
  props: [
    "liveData",
    "liveId",
    "userData",
    "chatlist",
    "roomHotList",
    "sendDame",
  ],
  components: { emotion, giftGiving },
  computed: {
    ...mapGetters(["M_token", "M_USER_DATA"]),
    // 取值
    ...mapState({
      userInfoData: (state) => state.home.userInfoData,
      listGift: (state) => state.home.listGift,
      isGiftsEffects: (state) => state.home.isGiftsEffects,
      token: (state) => state.home.token,
      isEffects: (state) => state.home.isEffects,
    }),

    calculativeWidth() {
      if (true) {
        let text = this.liveData ? this.liveData.desc : "",
          titleBox = document.querySelector(".chat_box .chat_box_head"),
          className = "";
        if (titleBox) {
          let spanW = document.querySelector(
              ".chat_box .chat_box_head .live-gg"
            ).offsetWidth,
            singleLength = (titleBox.offsetWidth - spanW - 25) / 12;
          if (text.trim().length > singleLength) {
            //如果一行文字超过单行可显示的字数
            className = "twinRowShow"; //双行显示
          } else {
            className = "singleShow"; //单行显示
          }
        }
        return className;
      } else {
        return "";
      }
    },
    getLevlIcon(i) {
      return function (i) {
        if (i == 0 || i == null) {
          return "";
        }
        //  else if (i == 7) {
        //   return require("../../assets/image_2/icon/" + (i - 1) + ".png");
        // }
        else if (i > 10) {
          return "";
        } else {
          return require("../../assets/image_2/icon/" + i + ".png");
        }
        return "";
      };
    },

    memberId() {
      if (this.userInfoData) {
        let id = this.userInfoData.memberId;
        return id;
      } else {
        return "";
      }
    },
    circleUrl() {
      let src = "";
      if (true) {
        src = localStorage.logo;
      }
      return src;
    },
  },
  watch: {
    "messageList.length": {
      handler(newValue, oldValue) {
        let ele = document.getElementById("chatRecord");
        if (ele.scrollHeight - ele.scrollTop - ele.clientHeight < 50) {
          this.newMess = 0;
          this.$nextTick(() => {
            ele.scrollTop = ele.scrollHeight;
          });
        } else {
          if (this.newMess > 98) {
            this.newMess = 99;
          } else {
            this.newMess++;
          }
        }
      },
    },
    isEffects(newVal, oldVal) {},
  },
  created() {
    // 根据https判断wss
    let H5Url = window.location.href;
    if (H5Url.indexOf("https") != -1) {
      this.WssUrl = "wss";
    } else {
      this.WssUrl = "ws";
    }
    document.body.addEventListener("touchmove", this.eventListenerHandle, {
      passive: false,
    });
  },
  mounted() {
    if (true) {
      this.chatRoom();
      this.getCarEffectList();
      window.addEventListener("scroll", this.handleScroll, true);
      var _self = this;

      if (document.body.clientWidth > 1600) {
        _self.setStyle = false;
        _self.fontSize = false;
      } else {
        _self.setStyle = true;
        _self.fontSize = true;
      }
      window.onresize = () => {
        if (document.body.clientWidth > 1600) {
          _self.setStyle = false;
          _self.fontSize = false;
        } else {
          _self.setStyle = true;
          _self.fontSize = true;
        }
      };
    }

    this.$nextTick(() => {
      let scroll = new BScroll(this.$refs.wrapper, {});
      GetUtils.touchScroll("chatRecord");
    });

    this.time22 = setInterval(() => {
      // console.log('+++++','websocket缓存的长度', this.messageList.length)
      if (this.messageList.length > this.jobNumber) {
        // console.log('-------','添加时删除'+ String(this.messageList.length - this.jobNumber))
        this.jobLists.push(
          ...this.messageList.splice(
            this.messageList.length -
              (this.messageList.length - this.jobNumber),
            this.messageList.length - this.jobNumber
          )
        );
      } else {
        this.jobLists.push(...this.messageList);
      }
      // console.log(...this.messageList)
      // 如果页面数据超过100条，渲染会卡顿，为了优化，让页面只有100条数据去渲染
      if (this.jobLists.length > this.jobNumber) {
        // this.jobLists.splice(this.jobLists.length - (this.jobLists.length -100), (this.jobLists.length -100));
        this.jobLists = this.jobLists.slice(-100);
      }
      // console.log('++++====','当前列表长度', this.jobLists.length, this.jobLists)
      this.messageList = [];
    }, 1000);

    global.barrageMsg_this = this;
  },
  methods: {
    ...mapMutations({
      SET_USER_DATA: "SET_USER_DATA",
      M_TOKEN: "M_TOKEN",
    }),
    ...mapActions({
      getIsEffectsFn: "getIsEffectsFn",
      getCarEffectListFn: "getCarEffectListFn",
    }),
    toBottom() {
      let ele = document.getElementById("chatRecord");
      this.newMess = 0;
      this.$nextTick(() => {
        ele.scrollTop = ele.scrollHeight;
      });
    },
    // andriod可见滚动条
    eventListenerHandle(e) {
      if (e._isScroller) return;
      e.preventDefault();
    },
    //------------------------------ 表情图片选中-----------------
    handleEmotion(emoji) {
      let elInput = this.$refs.chatinput;

      var startPos = elInput.selectionStart; // input 第0个字符到选中的字符
      var endPos = elInput.selectionEnd; // 选中的字符到最后的字符
      if (startPos === undefined || endPos === undefined) return;
      var txt = elInput.value;
      // 将表情添加到选中的光标位置
      var result = txt.substring(0, startPos) + emoji + txt.substring(endPos);
      elInput.value = result; // 赋值给input的value

      elInput.selectionStart = startPos + emoji.length;
      elInput.selectionEnd = startPos + emoji.length;
      this.ct = result; // 赋值给表单中的的字段
      // 重新定义光标位置
      this.$refs.chatinput.focus();
      this.emojiShow = false;
    },
    //----------------聊内容发送--------------
    sendCt() {
      if (
        Object.keys(this.userInfoData).length == 0 ||
        Object.keys(this.userInfoData.vipInfo).length == 0
      ) {
        Toast.fail("需要登录");
        return false;
      }
      if(!this.userInfoData.canTalk) {
        Toast.fail("您暂时无法发言，请稍后尝试！")
        return;
      }
      let message = {
        ct: "",
        ctType: 1,
        from: this.userInfoData.memberId,
        nickName: this.userInfoData.nickName,
        fromPhoto: this.userInfoData.logo,
        roomId: this.chatData.roomId,
        vipLevel: this.userInfoData.vipInfo.level,
      };
      let chatinput = this.$refs.chatinput.value;
      let limitTime = Number(this.liveData.limitTime) || 10;
      while (chatinput.lastIndexOf(" ") >= 0) {
        chatinput = chatinput.replace(" ", "");
      }
      if (chatinput.length == 0) {
        this.ct = "";
      } else if (chatinput.length > 30) {
        Toast("发送失败，字数超限");
      } else if (
        this.userInfoData.vipInfo.level < this.liveData.limitLevel &&
        this.chatType == 0
      ) {
        Toast("VIP等级不足，无法发言");
      } else {
        if (this.chatType == 0) {
          // 普通聊天信息
          if (this.chatData.roomId) {
            this.sendTimeLimit();
            if (!this.limitTimeShow) {
              Toast("发言太快，请休息一下!");
              return false;
            }
            message.ct = this.ct;
            this.websocketsend(JSON.stringify(message));
            this.ct = "";
          }
        }
        if (this.chatType == 1) {
          // 发送弹幕
          // this.showDialog = true;
          this.payMentTalk();
        }
      }
      this.chatShow = false;
    },
    // 限制
    sendTimeLimit() {
      if (this.limitTimeShow) {
        this.limitTimer = setInterval(() => {
          this.limitTimeNumb++;
          this.limitTimeShow = false;

          if (this.limitTimeNumb > this.liveData.limitTime) {
            this.limitTimeShow = true;
            this.limitTimeNumb = 0;
            clearInterval(this.limitTimer);
          }
        }, 1000);
      }
    },
    fixBlur() {
      // 解决ios输入框键盘弹起
      if (GetUtils.isiOS()) {
        // document.body.scrollTop = 0;
        // document.body.scrollIntoView();
        document.activeElement.blur();
      }
    },
    searchEvent() {
      // 关闭软键盘
      // console.log('回车事件')
      document.activeElement.blur();
    },
    handleInputKeydown(event) {
      let input1 = this.$refs.chatinput;
      let chatString = input1.value;
      if (event.keyCode === 8) {
        let indexEnd = input1.selectionStart - 1;
        let charToDelete = chatString.charAt(indexEnd);

        // delete the [***]
        if (charToDelete == "]") {
          // event.preventDefault();
          let indexStart = chatString.lastIndexOf("[", indexEnd);
          if (input1.setSelectionRange) {
            input1.setSelectionRange(indexStart, indexEnd + 1);
          }
        }
      }
    },
    // isIphone () {
    //   const u = navigator.userAgent;
    //   const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    //   if (isiOS) {
    //     return "ios";
    //   } else {
    //     return "andriod";
    //   }
    // },
    setClassFn(item) {
      let className1 = "";
      if (item) {
        let text = item.ct ? item.ct : "";
        // console.log('item.ct', text)
        if (text.trim().length > 13) {
          className1 = "twoShow";
        } else {
          className1 = "";
        }
      }
      return className1;
    },
    emojiBtn() {
      this.emojiShow = !this.emojiShow;

      if (this.emojiShow) {
        // 关闭软键盘
        setTimeout(() => {
          this.$refs.chatinput.blur();
        }, 300);
      } else {
        setTimeout(() => {
          this.$refs.chatinput.focus();
        }, 300);
      }
    },
    handChatShow() {
      this.chatShow = true;
      this.$nextTick(() => {
        this.$refs.chatinput.focus();
        this.ct = "";
      });

      gtag("event", "点击", {
        ct_click_type: "聊天栏_初始",
      });
    },
    handShare() {
      let value = "https://byqt6.com/";
      this.$copyText(value).then(function (e) {
        Toast.success("内容已复制，快去和朋友分享吧");
      });
    },
    handOffClick() {
      this.isUrlImg = !this.isUrlImg;
      this.getIsEffectsFn(this.isUrlImg);
    },
    // 隐藏聊天等功能仅显示视频
    switchChatFunc() {
      this.$bus.emit("show-chat");
    },
    onChange(index) {
      this.ct = "";
      this.$nextTick(() => {
        this.$refs.chatinput.focus();
      });
      if (index == 0) {
        gtag("event", "点击", {
          ct_click_type: "聊天栏_普通",
        });
      }
      if (index == 1) {
        gtag("event", "点击", {
          ct_click_type: "聊天栏_付费",
        });
      }
    },
    handGiftsOpen() {
      this.giftShowDialog = true;

      gtag("event", "点击", {
        ct_click_type: "礼物按钮",
      });
    },
    handGiftColse(e) {
      this.giftShowDialog = false;
    },

    handBtnOk(nub) {
      this.btnOkVale = nub;
      if (nub == 1) {
        this.showDialog = false;
      }
      if (nub == 2) {
        // 发送弹幕
        this.showDialog = false;
        // this.payMentTalk();
      }
    },
    async payMentTalk() {
      let res = await this.$homeApi.postRequest({
        apiName: "payTalk",
        roomId: this.chatData.roomId,
      });

      if (res) {
        if (res.code == "1" && res.data) {
          this.payTalkKey = res.data.payTalkKey;
          this.sendDamuTalk();
        } else {
          Toast.fail(res.msg);
        }
      } else {
        Toast.fail("网络故障，赠送失败");
      }
    },
    sendDamuTalk() {
      let message = {
        ct: "",
        ctType: 10,
        from: this.userInfoData.memberId,
        nickName: this.userInfoData.nickName,
        fromPhoto: this.userInfoData.logo,
        roomId: this.chatData.roomId,
        payTalkKey: this.payTalkKey,
        vipLevel: this.userInfoData.vipInfo.level,
      };
      let chatinput = this.$refs.chatinput.value;
      while (chatinput.lastIndexOf(" ") >= 0) {
        chatinput = chatinput.replace(" ", "");
      }
      if (chatinput.length == 0) {
        this.ct = "";
      } else {
        if (this.chatData.roomId) {
          message.ct = this.ct;
          this.websocketsend(JSON.stringify(message));
          this.ct = "";
        }
      }
      this.chatShow = false;
    },
    giftType(n) {
      // n = n.toString();
      let giftName = "";
      this.listGift.map((item, index) => {
        if (item.id == n) {
          giftName = item.name;
        }
      });
      return giftName;
    },
    addHight() {
      this.highLevelArr.push(1);
      this.giftList.unshift({ ct: "/porsche.svga" });
      // vip用户防止重复进入
      if (!this.highLevelIs) {
        this.highLevelIs = true;
        // this.highLevelVip3();
      }
    },
    highLevelVip3() {
      let _that = this;
      if (this.highLevelArr.length > 1) {
        let url = `/porsche.svga`;
        _that.highLevelArr.shift();
        console.log("高级特性", _that.highLevelArr);
        var player = new SVGA.Player("#high_level");
        player.loops = 1;
        var parser = new SVGA.Parser("#high_level"); // 如果你需要支持 IE6+，那么必须把同样的选择器传给 Parser。
        parser.load(url, function (videoItem) {
          player.setVideoItem(videoItem);
          player.startAnimation();
          player.onFinished(function () {
            setTimeout(() => {
              _that.highLevelVip3();
            }, 1000);
          });
        });
      } else {
        _that.highLevelIs = false;
      }
    },
    giftEffects() {
      let _this = this;

      if (_this.giftList.length >= 1) {
        // console.log("礼物特效", _this.giftList, );
        let giftId = _this.giftList[0].ct;
        // let url = `/giftjson/${giftId}/rocket.svga`;
        let svgaUrl =
          giftId.indexOf("/") !== -1 ? giftId : _this.getGiftSvga(giftId);
        // console.log('lilili', svgaUrl)
        // 不包含svga
        if (svgaUrl.indexOf(".svga") == -1) {
          return false;
        }

        _this.giftList.shift();

        var player = new SVGA.Player("#demoCanvas");
        player.loops = 1;
        var parser = new SVGA.Parser("#demoCanvas");
        parser.load(svgaUrl, function (videoItem) {
          if (player) {
            player.setVideoItem(videoItem);
            player.startAnimation();
            player.onFinished(function () {
              setTimeout(() => {
                _this.giftEffects();
              }, 1000);
            });
          }
        });
      } else {
        this.giftListIs = false;
        this.highLevelIs = false;
      }
    },
    // ==============图片匹配==================
    getGiftImg(n) {
      let giftUrl = "";
      this.listGift.map((item, index) => {
        if (item.id == n) {
          giftUrl = item.pic;
        }
      });
      return giftUrl;
    },
    getGiftSvga(id) {
      let svga = "";
      this.listGift.map((item, index) => {
        if (item.id == Number(id)) {
          svga = item.svga;
        }
      });
      return svga;
    },
    getLevlClass(item) {
      if (item.from == this.memberId) {
        return "c_r";
      } else {
        if (item.vipLevel <= 11 && item.vipLevel >= 8) {
          return "l8-11";
        } else if (item.vipLevel > 11) {
          return "l11-14";
        }
      }
    },
    // 单行双行
    getDoubleClass(str, ctType) {
      let text = str;
      let className = "";
      if (ctType == 10) {
        if (text.length > 12) {
          className = "twoRowShow orange";
        } else {
          className = "singShow orange";
        }
      } else {
        if (text.length > 12) {
          className = "twoRowShow";
        } else {
          className = "singShow";
        }
      }

      return className;
    },
    trim(str) {
      let string = "";
      let reg = /[\t\r\f\n\s]*/g;
      if (str) {
        string = str.replace(reg, "");
        string = string.replace(/&lt;br&gt;/g, "");
      }
      return string;
    },
    ggBtn() {
      this.ggshow = !this.ggshow;
    },
    //---------------聊天室数据参数获取--------------
    chatRoom() {
      if (this.chatlist) {
        this.chatData = this.chatlist;
        this.messageList =
          this.chatlist && this.chatlist.msgList
            ? this.chatlist.msgList.slice(-7)
            : [];
        // console.log('主播roomId', this.messageList )
        if (this.chatData.roomId) {
          this.initWebSocket();
        }
      }
    },

    async getCarEffectList() {
      let res_3 = await this.getCarEffectListFn({
        apiName: "getCarList",
      });
      if (res_3.code == "1") {
        this.carEffectList = res_3.data.listGift;
        // console.log('座驾', this.carEffectList)
      }
    },

    //Vue的WebSocket封装--------star
    initWebSocket() {
      //初始化weosocket
      let token = this.token;
      let roomId = this.chatData.roomId;
      let domain = this.chatData.domain.replace("http://", "");
      // let domain = "18.166.88.113:8003/";
      var wsUrl =
        this.WssUrl +
        "://" +
        domain +
        "/chatroomServer/" +
        token +
        "/" +
        roomId;
      if (token.indexOf("ws://") != -1 || token.indexOf("wss://") != -1) {
        wsUrl = token;
      }
      // console.log('wsUrl', wsUrl)
      this.websock = new WebSocket(wsUrl);

      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onclose = this.websocketclose;
      this.websock.onerror = this.websocketonerror;
      // 缓存
      // this.initWebSocket.instance = this;
      // return this;
    },
    //----------websocket连接建立  Start-------------
    websocketonopen() {
      let message = {
        ct: "",
        ctType: 4,
        from: this.memberId,
        roomId: this.chatData.roomId,
        nickName: this.userInfoData.nickName,
        fromPhoto: this.userInfoData.logo,
      };
      this.websocketsend(JSON.stringify(message));
      this.start();
    },
    //------------数据发送 send方法发送数据------------
    websocketsend(Data) {
      // console.log('发送websock', this.websock.readyState,)    // websock.readyState = 1正常
      //  3，关闭
      if (this.isOnlineWebsock()) {
        this.websock.send(Data);
      } else {
        Toast("网络波动，已重新连接");
        this.reconnect();
      }
    },
    //----------------- 开启心跳---------------------
    start() {
      let self = this;
      let message = {
        ct: "",
        ctType: 0,
        from: this.memberId,
        roomId: this.chatData.roomId,
        nickName: this.userInfoData.nickName,
        fromPhoto: this.userInfoData.logo,
      };

      self.timeoutObj && clearInterval(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setInterval(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，

        if (self.isOnlineWebsock()) {
          //如果连接正常
          self.websocketsend(JSON.stringify(message));
        } else {
          //否则重连
          self.reconnect();
        }

        // self.serverTimeoutObj = setTimeout(function () {
        //   //超时关闭
        //   // self.websock.close();
        // }, self.timeout);
      }, self.timeout);
    },
    // 判断是否连接
    isOnlineWebsock() {
      if (this.websock) {
        if (this.websock.readyState == 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    //----------websocket连接建立  end-------------

    //- --------websocketonmessage---数据接收-------Start---------------
    websocketonmessage(e) {
      const redata = JSON.parse(e.data);
      // console.log("消息列表", e.timeStamp, e.data);
      // 兼容iOS重复消息问题
      // console.log(redata, "redataredataredataredataredataredata");
      if (e.timeStamp == this.preMsg.timeStamp) return;
      this.preMsg = e;
      if (redata.ctType == 4) {
        // 欢迎
        if (redata.vipLevel > 0) {
          if (redata.vipLevel < this.chatData.vipIn || redata.anchorman) {
            this.welcomeListVip.push(redata);
            // vip用户防止重复进入
            if (!this.welcomeIs2) {
              this.welcomeIs2 = true;
              this.welcomeShowVip2();
            }
          } else {
            this.messageList.push(redata);

            // console.log('zuo驾特效', this.carEffectList, redata.vipLevel)
            // 高级用户进场特效  座驾
            if (this.carEffectList.length > 0) {
              let vipArr = this.carEffectList.filter((item) => {
                return redata.vipLevel == item.vip;
              });
              // console.log('座驾特效',vipArr)
              if (vipArr.length > 0) {
                let vipObj = { ct: vipArr[0].svga, vip: vipArr[0].vip };
                this.giftList.unshift(vipObj);
              }
            }
            // 防止重复进入
            if (!this.highLevelIs) {
              this.highLevelIs = true;
              this.giftEffects();
              // this.highLevelVip3();
            }
          }
        }
        //  else {
        //   this.welcomeList1.push(redata);
        //   if( !this.welcomeIs ) {
        //     this.welcomeIs = true;
        //     this.welcomeShow();
        //   }

        // }

        // setTimeout( () => {
        //   this.messageList = this.messageList.filter((item) => {
        //     return item.ctType !== 4 ;
        //   })
        // }, 5000)
      } else if (redata.ctType == 3) {
        // 礼物信息
        this.messageList.push(redata);

        //  礼物飘窗
        this.giftAnimList.push(redata);
        if (!this.giftAnimIs) {
          if (this.giftAnimList.length > 1) {
            this.giftAnimList.shift();
          }
          this.giftAnimIs = true;
          this.giftAnimShowMeth();
        }

        this.giftList.push(redata);
        // 防止重复进入
        if (!this.giftListIs) {
          this.giftListIs = true;
          this.giftEffects();
        }
        // console.log("礼物0",  this.giftAnimList,);
      } else if (redata.ctType == 8) {
        this.messageList = this.messageList.filter(
          (item) => item.from != redata.from
        );
        console.log("禁言", redata.from, this.memberId);
        if (redata.from == this.memberId) {
          this.jinyanTxt = redata.ct;
          this.jinyan = true;
        }
      } else if (redata.ctType == 9) {
        // 系统消息
        this.messageList.push(redata);
      } else if (redata.ctType == 10) {
        // 弹幕
        // this.$emit("sendDame", );
        this.sendDame(redata);
        this.messageList.push(redata);
        if (redata.from == this.memberId) {
          this.toBottom()
        }
      } else {
        // console.log("普通消息", redata);
        this.messageList.push(redata);
        if (redata.from == this.memberId) {
          this.toBottom()
        }
      }

      //收到服务器信息，心跳重置
      this.reset();
    },
    welcomeShow() {
      this.welcomeTxtShow = true;
      this.welcomeNickName = this.welcomeList1[0].nickName;
      this.welcomeLevel = this.welcomeList1[0].vipLevel;

      setTimeout(this.continue, 1000);
    },
    continue() {
      let that = this;
      that.welcomeNickName = "";
      if (this.welcomeList1.length > 1) {
        that.welcomeList1.shift();
        that.welcomeTxtShow = false;
        setTimeout(() => {
          that.welcomeShow();
        }, 800);
      } else {
        this.welcomeIs = false;
      }
    },
    // vip欢迎进入
    welcomeShowVip2() {
      this.welcomeTxtShow2 = true;
      this.welcomeNickName2 = this.welcomeListVip[0].nickName;
      this.welcomeLevel2 = this.welcomeListVip[0].vipLevel;
      this.fromPhoto2 = this.welcomeListVip[0].fromPhoto;
      this.welcomeAnchorman = this.welcomeListVip[0].anchorman;
      setTimeout(this.continueVip2, 2000);
    },
    continueVip2() {
      this.welcomeTxtShow2 = false;
      this.welcomeListVip.shift();
      if (this.welcomeListVip.length > 1) {
        setTimeout(() => {
          this.welcomeShowVip2();
        }, 800);
      } else {
        this.welcomeIs2 = false;
      }
    },
    //---------------重置心跳-------------
    reset() {
      var that = this;
      //清除时间
      clearInterval(that.timeoutObj);
      clearTimeout(that.serverTimeoutObj);
      //重启心跳
      that.start();
    },
    //- --------websocketonmessage---数据接收-------end---------------

    //- --------websocketclose--链接关闭-----Start------------------------
    websocketclose(e) {
      console.log("连接关闭", e.code, e.wasClean);
      // console.log(
      //   "websocket 断开: " + e.code + " " + e.reason + " " + e.wasClean
      // );
      //重连
      // this.reconnect();
    },
    reconnect() {
      //重新连接
      if (this.lockReconnect) {
        return;
      }
      this.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      this.timeoutnum && clearTimeout(this.timeoutnum);
      this.timeoutnum = setTimeout(() => {
        //新连接
        this.websock.close();
        this.websock = null;
        this.initWebSocket();
        this.lockReconnect = false;
      }, 3000);
    },
    //- --------websocketclose-------end------------------------

    //- --------websocketclose--重连-----Start------------------------
    websocketonerror() {
      // console.log("出现错误");
      this.reconnect();
    },
    //- --------websocketclose--重连-----end------------------------

    // --------------------将匹配结果替换表情图片---------------------
    emotionImgSrc(res) {
      let word = res.replace(/\[|]/g, ""); //正则加'g'全文查找匹配
      const list = emojiData.emojiList;
      let index = list.indexOf(word);
      return (
        "<img class='ct_img' src=" +
        require("../../assets/emojipng/" + list[index] + ".png") +
        ">"
      );
    },
    handleScroll() {
      let ele = document.getElementById("chatRecord");
      if (ele.scrollHeight - ele.scrollTop - ele.clientHeight < 50) {
        //  if(ele.scrollHeight - ele.scrollTop === ele.clientHeight){
        this.newMess = 0;
      }
    },
    // 登录 ----------start-------------lcy
    login() {
      // this.$emit("postLoginStatus", true);
    },
    //===================赠送礼物===============
    giftAnimShowMeth() {
      this.giftAnimData = this.giftAnimList[0];
      this.giftAnimShow = true;

      setTimeout(this.giftAnimContinue, 2300);
    },
    giftAnimContinue() {
      var that = this;
      this.giftAnimShow = false;
      if (that.giftAnimList.length > 1) {
        that.giftAnimList.shift();
        setTimeout(() => {
          that.giftAnimShowMeth();
        }, 500);
      } else {
        this.giftAnimIs = false;
      }
    },
  },
  filters: {},
  destroyed() {
    if (this.websock != undefined) {
      this.websock.close(); //离开路由之后断开websocket连接
    }
    clearInterval(this.timeoutObj);
    clearTimeout(this.serverTimeoutObj);
    clearTimeout(this.timeoutnum);
    clearTimeout(this.setTime);

    document.body.removeEventListener("touchmove", this.eventListenerHandle, {
      passive: false,
    });
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.time22);
    });
  },
};
</script>
<style lang="scss" >
.chat_box {
  height: auto;
  position: relative;
  padding: 8px 0 0 0;
  // overflow: hidden;
  // overflow-x: hidden;

  .van-overlay {
    background-color: rgba(0, 0, 0, 0);
  }

  .gift_animation {
    z-index: 1;
    color: #fff;
    position: absolute;
    top: -86px;
    left: 15px;
    background: linear-gradient(
      90deg,
      rgba(44, 103, 255, 0.61) 11.98%,
      rgba(255, 64, 213, 0.41) 63.54%,
      rgba(255, 0, 234, 0) 95.31%
    );
    opacity: 1;
    border-radius: 26px;
    padding: 2px 15px 2px 10px;
    display: flex;

    .head {
      width: 38px;
      height: 38px;
      margin: 0 6px 0 0;
      position: relative;

      .headImg {
        display: block;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        object-fit: cover;
      }
      .vm_giftIcon {
        display: inline-block;
        height: 12px;
        position: absolute;
        bottom: 0;
        left: 0px;
      }
    }

    .db {
      display: block;
    }

    .f14 {
      font-size: 13px;
      line-height: 18px;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
    }

    .f12 {
      font-size: 10px;
      line-height: 14px;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);

      .giftName {
        color: rgba(34, 255, 228, 1);
        margin: 0 0 0 5px;
      }
    }

    .v_m11 {
      .v_top {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }
    }

    .v_m22 {
      width: 50px;
      position: relative;
      margin-left: 12px;

      img {
        display: block;
        width: 100%;
        position: absolute;
        bottom: -6px;
        left: 0;
      }
    }

    .v_numb {
      width: 15px;
      position: relative;

      i {
        position: absolute;
        bottom: 0px;
        left: 30px;
        font-size: 32px;
        background: linear-gradient(180deg, #ffd80a 35.94%, #ff932f 76.56%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        border: 1px solid transparent;
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
        transform: matrix(1, 0.01, -0.16, 0.99, 0, 0);
      }
    }

    span {
      display: inline-block;
    }
  }

  .chat_box_head {
    position: absolute;
    padding: 10px 10px 5px;
    font-size: 14px;
    // border-bottom: 1px solid #e5e5e5;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    top: 0;
    width: 100%;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    color: #666;
    background: transparent;

    .chat_box_gg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &.twinRowShow {
    //双行标题显示
    .chat_box_head {
      z-index: 99;
      min-height: 50px;
      line-height: 25px;
      transition: all 0.5s;
    }

    .z1 {
      z-index: 1;
      height: auto;
      display: block;
      transition: all 0.5s;
    }
  }

  &.singleShow {
    .chat_box_content {
      margin-top: 40px;
      height: 365px;
    }
  }

  .chat_box_content {
    padding: 0 45px 0 15px;
    background: transparent;
    // height: calc(100vh - 79.5vh);
    height: 170px;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    position: relative;
    .massge_ctn {
      min-height: 180px;
      height: auto;
      position: absolute;
      padding-bottom: 39px;
    }

    p {
      font-size: 13px;
      height: auto;
      color: rgba(255, 255, 255, 1);
      overflow: hidden;
      margin: 5px 0 0 0;
      padding: 3px 10px 3px 4px;
      background: rgba(0, 0, 0, 0.35);
      border-radius: 20px;

      i {
        color: red;
        font-weight: bolder;
        margin-left: 5px;
      }

      .giftimg {
        width: 22px;
        vertical-align: sub;
      }

      .orange {
        color: #ff7e00;
      }
    }
    .child_time:nth-last-child(7) {
      .timeMessage {
        opacity: 0.2;
        transition: opacity 0.5s ease-out;
      }
      .giftMessage {
        opacity: 0.2;
        transition: opacity 0.5s ease-out;
      }
    }
    .child_time:nth-last-child(6) {
      .timeMessage {
        opacity: 0.3;
        transition: opacity 0.5s ease-out;
        // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
      }
      .giftMessage {
        opacity: 0.3;
        transition: opacity 0.5s ease-out;
      }
    }
    .child_time:nth-last-child(5) {
      .timeMessage {
        opacity: 0.8;
        transition: opacity 0.5s ease-out;
      }
      .giftMessage {
        opacity: 0.8;
        transition: opacity 0.5s ease-out;
      }
    }

    .timeMessage {
      display: inline-flex;
      align-items: flex-start;

      &.twoShow {
        border-radius: 5px;
      }

      .timeImg {
        margin-right: 3px;
        height: 15px;
        margin-top: 1px;
      }
      .messageRight {
        .name_color {
          max-width: 240px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &.singShow {
          display: flex;
        }
      }

      .ct {
        min-width: 80px;
        &.singShow {
          display: inline;
          white-space: nowrap;
        }
      }

      .laba {
        display: inline-block;
        width: 22px;
        height: 15px;
        position: relative;
        margin-right: 3px;

        img {
          display: block;
          width: 100%;
          position: absolute;
          bottom: -7px;
          left: 0;
        }
      }
    }

    .giftMessage {
      display: inline-flex;
      align-items: flex-start;

      .msg_pic {
        display: inline-block;
        width: 24px;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          position: absolute;
          bottom: -7px;
          left: 0;
        }
      }
      .giftIcon {
        margin-right: 6px;
        height: 15px;
      }
    }
    .welcomeMessage {
      display: inline-flex;
      align-items: flex-start;
      padding: 3px 10px 3px 6px;
      .orange {
        color: #ff7e00;
      }
    }
    .systemMessage {
      &.twoShow {
        border-radius: 5px;
      }
      .orange {
        color: #ff7e00;
      }
    }

    .welcome_txt {
      display: inline-flex;
    }

    // &::-webkit-scrollbar {
    //   width: 0px;
    //   height: 0px;
    //   background-color: #f5f5f5;
    //   display: none;
    // }
  }

  .name_color {
    padding: 0;
    color: #79dbf1;
    cursor: pointer;
    font-size: 13px;

    img {
      vertical-align: text-top;
      margin-right: 3px;
      height: 15px;
    }
  }

  .welcome {
    .welcome_txt {
      color: #fff;

      .name_color {
        font-size: 12px !important;
        color: #3c9cfe;
      }

      .c_font {
        font-size: 12px;
      }

      img {
        vertical-align: bottom;
      }
    }
  }

  .webcast_welcome {
    overflow: hidden;
    .c_font {
      color: rgba(109, 109, 109, 1);
    }
  }

  .toBottom {
    cursor: pointer;
    position: absolute;
    bottom: 100px;
    text-align: center;
    width: 100%;

    span {
      background: rgba(82, 82, 82, 0.8);
      padding: 5px 20px;
      color: #ff7878;
      font-size: 12px;
      border-radius: 100px;
    }
  }

  .send_box {
    width: 100%;
    padding: 0 8px 31px 15px;
    height: auto;
    box-sizing: border-box;
    .login_tip {
      width: 260px;
      height: 40px;
      position: absolute;
      left: 15px;
      bottom: 12px;
      z-index: 1;
      text-align: left;
      top: 1px;
      right: 71px;
      background: #fff;
      border-radius: 16px;
      line-height: 40px;
      padding: 0 15px;
      color: #999;
      box-sizing: border-box;
    }

    .el-input-group__append {
      background: #ff7e00;
      color: #fff;

      span {
        font-size: 14px;
      }
    }

    .el-input-group__prepend {
      background: transparent;
      border-right: none;
      padding: 0 15px;
    }
  }

  .emoji_box {
    width: 355px;
  }

  .el-input__inner {
    height: 50px;
    border-right: none;
    padding-left: 0;
  }

  .ct_img {
    width: 22px;
    height: 22px;
    vertical-align: middle;
  }
}

.send_box {
  .el-input-group__append {
    background: #ff7e00;
    color: #fff;

    span {
      font-size: 14px;
    }
  }

  .el-input-group__prepend {
    background: transparent;
    border-right: none;
    padding: 0 15px;
  }
}

// 苹果8手机
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .chat_box .chat_box_content {
    height: 170px;
  }
}
</style>
